@tailwind base;
@tailwind components;
@tailwind utilities;

/* adding fonts below */
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  /* font-family: 'Segoe UI', 'Roboto', sans-serif; */
}

html {
  scroll-behavior: smooth;
}


/* ============================================================= */
.fade-in-text {
  animation: left-to-right-fade-in 1.5s ease-in;
  -webkit-mask-repeat: no-repeat;
}

@keyframes left-to-right-fade-in {
  0% {
    -webkit-mask-size: 0%;
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0)
    );
  }
  100% {
    -webkit-mask-size: 100%;
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0)
    );
  }
}

.container {
  @apply w-[90%] max-w-[1600px] mx-auto
}

.text-cls{
  @apply text-left text-blue-primary font-normal text-xl lg:text-2xl
}

/* looper */
@keyframes slideAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnimation linear infinite;   
}

.looper__listInstance {
  display: flex;
  align-items: center;
  width: max-content;

  animation: none;
}
/* looper */
